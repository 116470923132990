/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import "bootswatch/dist/quartz/variables";
// @import "bootstrap/scss/bootstrap";
// @import "bootswatch/dist/quartz/bootswatch";

.dashed {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3pt;
}

.bg-light a {
    color: #000;
}

.bg-warning {
    color: #000;
    background-color: #FFFF00;
}

.icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px
}

.card .text-muted {
    color: #818181 !important;
}

.gameicon {
    margin-left: -8px;
}

body {
    background: linear-gradient(90deg, #742022, #E0912F);
        // background: linear-gradient(90deg, #3E8D00, #FF5B4D, #9870F7, #6481FF);      
}

.bg-adhoc {
    --bs-bg-opacity: 1;
    background-color: red !important;
}

[hidden] { display: none !important;}

h3 {
    margin-bottom: 0;
}

.bg-official {
    --bs-bg-opacity: 1;
    color: #000;
    background-color: #daa520 !important;
}

code {
    color: white !important;
    background-color: rgba(0, 0, 0, .25);
}

.tooltip-inner {
    color: white !important;
}